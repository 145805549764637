
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { modifyPaymentAmount } from "@/core/services/api/payments";
import localstorage from "@/core/services/LocalstorageService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { Modal } from "bootstrap";

export default defineComponent({
  props: ["bill"],
  setup(props, { attrs, emit, slots }) {
    let modifyAmountModal;
    const formRef = ref<null | HTMLFormElement>(null);
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    let paymentGuid;
    let additionalData = reactive({
      amount: 0,
    });
    const rules = reactive({
      amount: [
        {
          required: true,
          message: "請填選數量",
          trigger: "blur",
        },
      ],
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          let request = {
            amount: additionalData.amount,
          };
          await modifyPaymentAmount(currentSpaceGuid!, paymentGuid, request)
            .then(() => {
              modifyAmountModal.hide();
              Swal.fire("修改完成!", "", "success");
              emit("changesComplete");
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: err.response.data.errors[0].errorDescription,
              });
              additionalData.amount = props.bill.amount;
            });
        }
      });
    };

    watch(
      props.bill,
      () => {
        additionalData.amount = props.bill.amount;
        paymentGuid = props.bill.paymentGuid;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    onMounted(() => {
      modifyAmountModal = new Modal(
        document.getElementById("modifyAmount-modal")
      );
    });

    return {
      rules,
      additionalData,
      submit,
      formRef,
    };
  },
});
