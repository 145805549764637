<template>
  <Datatable
    :table-data="tableData"
    :table-header="tableHeader"
    :enable-items-per-page-dropdown="false"
    :openTextEnd="false"
    :striped="true"
  >
    <template v-slot:cell-type="{ row: tableData }">
      <div style="text-align: left">
        {{ itemTypeDictionary[tableData.type] }}
      </div>
    </template>
    <template v-slot:cell-item="{ row: tableData }">
      <div style="text-align: left">{{ getItem(tableData) }}</div>
    </template>
    <template v-slot:cell-remark="{ row: tableData }">
      <div style="text-align: left">{{ tableData.description }}</div>
    </template>
    <template v-slot:cell-beginTime="{ row: tableData }">
      <div v-if="tableData.chargeExtend !== null" style="text-align: left">
        <span>{{ getTimeString(getToken(tableData.item).beginTime) }}</span>
      </div>
    </template>
    <template v-slot:cell-amount="{ row: tableData }">
      <div v-if="tableData.chargeExtend !== null" style="text-align: left">
        <span>{{ tableData.chargeExtend.amount }}</span>
      </div>
    </template>
    <template v-slot:cell-timeSpan="{ row: tableData }">
      <div v-if="tableData.chargeExtend !== null" style="text-align: left">
        <span>{{ tableData.chargeExtend.rule.timeSpan }}分鐘</span>
      </div>
    </template>
    <template v-slot:cell-quantity="{ row: tableData }">
      <div v-if="tableData.chargeExtend !== null" style="text-align: left">
        <span>{{ tableData.quantity }}</span>
      </div>
    </template>
    <template v-slot:cell-countAmount="{ row: tableData }">
      {{ tableData.total }}
    </template>
  </Datatable>
  <hr />
  <div class="row justify-content-end">
    <div class="col- mr-20">總金額:{{ order.amount }}</div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { getFacilityBySpace } from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import {
  PaymentType,
  ItemType,
} from "@/core/services/responsesModel/orderExtend";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";

export default defineComponent({
  components: {
    Datatable,
  },
  props: ["order","paymentDetail", "tokensDictionary", "couponsDictionary"],
  setup(props) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const itemTypeDictionary = {
      Token: "票券",
      Coupon: "折價券",
    };
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "品項類別",
        key: "type",
        sortable: true,
      },
      {
        name: "品項",
        key: "item",
        sortable: true,
      },
      {
        name: "票券記事",
        key: "remark",
        sortable: true,
      },
      {
        name: "起始時間",
        key: "beginTime",
        sortable: true,
      },
      {
        name: "單價",
        key: "amount",
        sortable: true,
      },
      {
        name: "時間單位",
        key: "timeSpan",
        sortable: true,
      },
      {
        name: "數量",
        key: "quantity",
        sortable: true,
      },
      {
        name: "小計",
        key: "countAmount",
        sortable: true,
      },
    ]);
    let facilitiesDictionary = new Map();
    let facilityArray;
    let order = reactive({})

    const getTimeString = (time, format) => {
      return getLocalTimeString(time, format);
    };
    const handleGetFacilityBySpace = async () => {
      facilityArray = (await getFacilityBySpace(currentSpaceGuid!)).map(
        (o) => o.facility
      );
    };
    const getToken = (guid) => {
      if (props.tokensDictionary.has(guid)) {
        return props.tokensDictionary.get(guid);
      } else {
        return guid;
      }
    };
    const getFacility = (guid) => {
      if (facilitiesDictionary.has(guid)) {
        return facilitiesDictionary.get(guid);
      } else {
        return guid;
      }
    };
    const getCoupon = (guid) => {
      if (props.couponsDictionary.has(guid)) {
        return props.couponsDictionary.get(guid);
      } else {
        return guid;
      }
    };
    const getItem = (item) => {
      facilityArray.forEach((o) => {
        facilitiesDictionary.set(o.guid, o);
      });
      let lineItem = item;

      if (lineItem.type === ItemType.Token) {
        let token = getToken(lineItem.item);

        let itemFacility = getFacility(token.facility);

        if (itemFacility !== undefined) {
          return (
            getToken(lineItem.item).runningNumber +
            "(" +
            itemFacility.name +
            ")"
          );
        } else {
          return "";
        }
      } else {
        let coupon = getCoupon(lineItem.item);
        if (coupon !== undefined) {
          return coupon.title;
        }
      }

      return "";
    };
    watch(
      props.paymentDetail,
      () => {
        tableData.splice(0, tableData.length, ...props.paymentDetail);
        Object.assign(order,props.order)
      },
      {
        immediate: true,
        deep: true,
      }
    );

    const init = () => {
      handleGetFacilityBySpace();
    };
    init();
    onMounted(() => {
      MenuComponent.reinitialization();
    });
    return {
      tableData,
      tableHeader,
      itemTypeDictionary,
      getItem,
      getTimeString,
      getToken,
    };
  },
});
</script>

