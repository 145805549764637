import ApiService from "@/core/services/ApiService";
import { PaymentExtend } from "@/core/services/responsesModel/paymentExtend"

export function modifyPaymentAmount(spaceGuid: string,paymentGuid,request): Promise<PaymentExtend> {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Space/${spaceGuid}/Payment/${paymentGuid}/Amount`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}