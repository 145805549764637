
import { defineComponent, reactive, ref, toRefs, onMounted } from "vue";
import { MenuComponent } from "@/assets/ts/components";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import moment from "moment";
import {
  getOrderBySpace,
  orderModeDictionary,
  orderStatusDictionary,
  paymentType,
  manualPaymentCheck,
} from "@/core/services/api/order";
import { getTokens } from "@/core/services/api/tokens";
import { getCoupons } from "@/core/services/api/coupons";
import {
  PaymentType,
  ItemType,
  PaymentServerStatus,
} from "@/core/services/responsesModel/orderExtend";
import { useRouter, useRoute } from "vue-router";
import { Modal } from "bootstrap";
import PaymentDetailModal from "./components/PaymentDetailModal.vue";
import ModifyAmount from "./components/ModifyAmount.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { getSimpleUsers } from "@/core/services/api/members";
import { Form } from "vee-validate";

export default defineComponent({
  components: {
    Datatable,
    PaymentDetailModal,
    ModifyAmount,
  },
  emits: ["reinitialize"],
  setup() {
    const currentItem = reactive({});
    let tokensDictionary = reactive(new Map());
    let couponsDictionary = reactive(new Map());
    const route = useRoute();
    const isLoadind = ref(false);
    // 帳單狀態
    const paymentServerStatus = {
      Unpaid: "尚未付款",
      Captured: "已請款",
      Refunded: "已退款",
    };
    // 第三方狀態
    const threePartyStatus = {
      None: "無第三方狀態",
      Authorized: "已授權",
      Capturing: "請款中",
      Captured: "已請款",
      Refunded: "已退款",
      PartialRefunded: "部分退款",
      Pending: "tappay 未處理",
      Cancel: "tappay 取消",
      Error: "tappay 錯誤",
    };
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const timePickerData: any = reactive({
      value: [
        moment()
          .subtract(2, "month")
          .startOf("month")
          .format("YYYY-MM-DDTHH:mm:ssZ"),
        moment().subtract().endOf("day").format("YYYY-MM-DDTHH:mm:ssZ"),
      ],
    });
    // 時間過濾
    let timeFilter = {
      beginTime: timePickerData.value[0],
      endTime: timePickerData.value[1],
    };
    const search: any = ref("");
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const typeOptions = [
      { text: "免費方案", value: "Free" },
      { text: "Tappay付款", value: "Tappay" },
      { text: "點數付款", value: "SpacesPoint" },
      { text: "手動處理", value: "Manual" },
    ];
    const tableHeader = ref([
      {
        name: "帳單編號",
        key: "runningNumber",
        sortable: true,
      },
      {
        name: "聯絡人",
        key: "consumer",
        sortable: true,
      },
      {
        name: "訂購人",
        key: "user",
        sortable: true,
      },
      {
        name: "消費日期",
        key: "createTime",
        sortable: true,
      },
      {
        name: "狀態",
        key: "status",
        sortable: true,
      },
      {
        name: "模式",
        key: "mode",
        sortable: true,
      },
      {
        name: "金額",
        key: "amount",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    let currentLineItems: any = reactive([]);
    const typeSelected: any = ref(["Free", "Tappay", "Manual", "SpacesPoint"]);
    const userInfoVisible = ref(false);
    const orders: any = reactive([]);
    const usersData: any = reactive([]);
    const billData: any = reactive([]);
    const currentUserData = reactive({});

    // ------------------方法---------------------

    const reinitialize = () => {
      init();
    };

    const setCurrentPayments = async (item) => {
      let filterLineItems = item.orderData.lineItems.filter(
        (o) => o.payment == item.paymentGuid
      );
      currentLineItems.splice(0, currentLineItems.length, ...filterLineItems);
      Object.assign(currentItem, item);
      await fetchItemData(item);
    };

    const fetchItemData = async (item) => {
      const tokenGuidArray = item.orderData.lineItems
        .filter((o) => o.type === ItemType.Token)
        .map((o) => o.item);
      if (tokenGuidArray.length > 0) {
        const tokensGuid = { tokens: tokenGuidArray };
        await getTokens(tokensGuid).then((o) => {
          //   let dictionary = new Map();
          o.forEach((token) => {
            tokensDictionary.set(token.guid, token);
            // dictionary.set(token.guid, token);
          });
          // tokensDictionary = dictionary;
        });
      }
      const couponGuidArray = item.orderData.lineItems
        .filter((o) => o.type === ItemType.Coupon && o.item !== "")
        .map((o) => o.item);
      if (couponGuidArray.length > 0) {
        const codesGuid = { codesGuid: couponGuidArray };
        await getCoupons(codesGuid).then((o) => {
          //   let dictionary = new Map();
          o.forEach((coupon) => {
            // dictionary.set(coupon.codeGuid, coupon);
            couponsDictionary.set(coupon.codeGuid, coupon);
          });
          //   couponsDictionary = dictionary;
        });
      }
    };

    const setSearch = () => {
      if (route.params.orderRunningNumber)
        search.value = route.params.orderRunningNumber;
    };

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    const updateTime = (time) => {
      timeFilter.beginTime = getUtcTimeString(time[0]);
      timeFilter.endTime = getUtcTimeString(time[1]);
      setTableData();
    };
    const handleGetOrderBySpace = async () => {
      let ordersRequestBody: any = {
        space: localstorage.getCurrentSpaces()?.space.guid,
        beginTime: timeFilter.beginTime,
        endTime: timeFilter.endTime,
      };
      return await getOrderBySpace(ordersRequestBody);
    };

    const changeCheckList = async () => {
      await init();
    };

    // 根據篩選過濾資料
    const filterListByCheck = (list) => {
      return list.filter((o) => typeSelected.value.includes(o.type));
    };

    // 取得最終資料
    const getFinalList = (list) => {
      let finalList = [];
      finalList = filterListByCheck(list);
      return finalList;
    };

    //取得所有帳單
    const setBillData = async () => {
      let bill: any = [];
      orders.forEach((order) => {
        order.payments.forEach((payment) => {
          bill.push(handleBillData(payment, order));
        });
      });
      billData.splice(0, billData.length, ...bill);
    };

    // 根據guid取得userData
    const getUsetDataByGuid = (guid) => {
      let data = usersData.filter((o) => o.simpleUser.guid == guid);
      return data[0].simpleUser;
    };

    // 處理帳單資料
    const handleBillData = (payment, order) => {
      return {
        orderData: order,
        orderRunningNumber: order.order.runningNumber,
        runningNumber: payment.runningNumber,
        consumer: payment.contact.name,
        payment: payment,
        user: getUsetDataByGuid(payment.user).name,
        userData: getUsetDataByGuid(payment.user),
        createTime: getLocalTimeString(payment.createTime),
        mode: paymentType[payment.type],
        amount: payment.amount,
        isModify: false,
        paymentGuid: payment.guid,
        status: getPaymentStatus(payment),
        serverStatus: payment.serverStatus,
        type: payment.type,
      };
    };

    // 取得帳單狀態
    const getPaymentStatus = (payment) => {
      if (payment.type === PaymentType.Tappay) {
        return threePartyStatus[payment.threePartyStatus];
      }
      return paymentServerStatus[payment.serverStatus];
    };

    const setTableData = async () => {
      let finalList: any = getFinalList(billData);
      tableData.splice(0, tableData.length, ...finalList);
      initTableData.splice(0, initTableData.length, ...finalList);
    };

    // 可線下付款核對
    const canPaymentCheck = (item) => {
      let isUnpaid = item.serverStatus == PaymentServerStatus.Unpaid;
      let isManual = item.type == PaymentType.Manual;
      return isUnpaid && isManual;
    };

    // 線下付款核對
    const handleManualPaymentCheck = async (item) => {
      await Swal.fire({
        title: "付款核對",
        text: "此動作無法恢復，請務必確認",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "確認",
        cancelButtonText: "取消",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let request = {
            capturedTime: getUtcTimeString(moment()),
            remark: "",
          };
          await manualPaymentCheck(currentSpaceGuid, item.paymentGuid, request);
          Swal.fire("付款已完成核對", "", "success");
          await init();
        }
      });
    };

    // 取得所有user
    const setUsersData = async () => {
      let users: any = [];
      console.log("orders", orders);
      for (const item of orders) {
        let payments = item.payments;
        users.push(...payments.map((o) => o.user));
      }
      users = Array.from(new Set(users));
      let request = { users: users };
      usersData.splice(0, usersData.length, ...(await getSimpleUsers(request)));
      console.log("usersData", usersData);
    };

    const showUserInfo = (item, category) => {
      if (category == "consumer") {
        Object.assign(currentUserData, {
          name: item.payment.contact.name,
          email: item.payment.contact.email,
        });
      } else if (category == "user") {
        Object.assign(currentUserData, {
          name: item.userData.name,
          email: item.userData.email,
        });
      }
      userInfoVisible.value = true;
    };

    const init = async () => {
      isLoadind.value = true;
      orders.splice(0, orders.length, ...(await handleGetOrderBySpace()));
      await setUsersData();
      await setBillData();
      await setTableData();
      isLoadind.value = false;
      setSearch();
      tableSearch();
    };
    init();

    onMounted(() => {
      MenuComponent.reinitialization();
    });
    return {
      ...toRefs(timePickerData),
      updateTime,
      search,
      tableSearch,
      tableHeader,
      tableData,
      isLoadind,
      setCurrentPayments,
      currentLineItems,
      couponsDictionary,
      tokensDictionary,
      currentItem,
      reinitialize,
      canPaymentCheck,
      handleManualPaymentCheck,
      typeSelected,
      changeCheckList,
      typeOptions,
      userInfoVisible,
      showUserInfo,
      currentUserData,
    };
  },
});
